@tailwind base;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
  margin: 2px 0;
}

*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 14px;
  border: 3px solid var(--primary);
}

@tailwind components;
@tailwind utilities;

:root {
  --font-base: Inter, sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-base);
  width: 100vw;
  overflow-x: hidden;
}

body.intercom-h-0 {
  @media only screen and (max-width: 450px) {
    height: 0px !important;
  }
}

@media (max-width: 1024px) {
  .intercom-lightweight-app-launcher {
    bottom: 72px !important;
  }

  /*localhost*/
  .intercom-dfosxs {
    bottom: 72px !important;
  }

  iframe[name='intercom-notifications-frame'] {
    bottom: 124px !important;
  }
}
